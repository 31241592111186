import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { renderTitle, renderText } from '../../helpers/api';
import VisibiltySensor from 'react-visibility-sensor';
import VideoScroller from '../../components/posts/VideoScroller/VideoScroller';
import LoadingAnimation from '../../components/elements/LoadingAnimation/LoadingAnimation';
import ScrollToHash from '../../components/nav/ScrollToHash/ScrollToHash';

@inject('pagesStore', 'scrollStore')
@observer
class RacismStage extends Component {

  slug = 'rassismus';
  containerRef = React.createRef();
  scrollConfig = {
    name: 'RacismStage',
    elementRef: this.containerRef,
    path: this.props.path
  }

  constructor (props) {
    super(props);
    this.state = {
      showComponent: false
    };
  }

  componentDidMount() {
    this.props.scrollStore.subscripeToScrollManager(this.scrollConfig);
    if (this.props.match) {
      this.props.pagesStore.fetchIfNeeded(this.slug);
      this.setState({
        showComponent: true
      });
      if (!this.props.location.hash) {
        setTimeout(
          () => {
            this.props.scrollStore.animateScrollTo();
        }, 10);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match && !this.state.showComponent) {
      this.props.pagesStore.fetchIfNeeded(this.slug);
      this.setState({
        showComponent: true
      });
    }
    const noScroll = this.props.location.hash || (this.props.location.state && this.props.location.state.noScroll);
    if (
      !noScroll &&
      (!prevProps.match || !prevProps.match.isExact) &&
      (this.props.match && this.props.match.isExact)
    ) {
      setTimeout(
        () => {
          this.props.scrollStore.animateScrollTo();
      }, 10);
    }
  }

  componentWillUnmount() {
    this.props.scrollStore.unsubscripeFromScrollManager(this.scrollConfig);
  }

  onVisibiltyChange = (isVisible) => {
    if (isVisible && !this.state.showComponent && !this.props.scrollStore.isDisabled) {
      this.props.pagesStore.fetchIfNeeded(this.slug);
      this.setState({
        showComponent: true
      });
    }
    // console.log('%s is now %s', this.slug, isVisible ? 'visible' : 'hidden', this.props.scrollStore.isDisabled) ;
  }

  renderHead() {
    if (this.props.pagesStore.isFetched.get(this.slug) && this.props.pagesStore.data.posts[this.slug].title && this.props.match) {
      return renderTitle(this.props.pagesStore.data.posts[this.slug].title);
    } else {
      return null;
    }
  }

  render () {
    const isFetched = this.props.pagesStore.isFetched.get(this.slug);
    const post = this.props.pagesStore.data.posts[this.slug];

    return (
      <VisibiltySensor partialVisibility={true} delayedCall={true} offset={{top:100, bottom: 100}} onChange={this.onVisibiltyChange}>
        <div className="nc-no-racism-content nc-home__stage -no-racism" ref={this.containerRef}>
          { isFetched ? (
            <div>
              {this.renderHead()}
              {this.props.match && this.props.match.isExact && <ScrollToHash/>}
              <div className="nc-home__content">
                <div className="row">
                  <div className="col-12 col-lg-8 offset-lg-2">
                    <h2>{post.fields && post.fields.field_nc_page_title && post.fields.field_nc_page_title}</h2>
                    {post.fields && post.fields.field_nc_page_excerpt && renderText(post.fields.field_nc_page_excerpt)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-8 offset-lg-2">
                    {post.fields && post.fields.field_nc_video_slider && <VideoScroller videos={post.fields.field_nc_video_slider}/>}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-8 offset-lg-2">{renderText(post.content)}</div>
                </div>
              </div>
            </div>
          ) : (
            <div>{this.state.showComponent ? <LoadingAnimation/> : <span>&nbsp;</span>}</div>
          )}
        </div>
      </VisibiltySensor>
    )
  }
}

export default RacismStage;