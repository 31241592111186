import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

@inject('navigationStore', 'scrollStore')
@observer
export default class FirstAidTab extends Component {

  render() {
    const show = this.props.navigationStore.showFirstAidTab;
    return (
      <div className={'nc-first-aid-tab ' + (show ? 'show' : 'hide')}>
        <button type="button" className="btn btn-danger" onClick={this.props.scrollStore.onClickFirstAidTab}>Erste Hilfe</button>
      </div>
    );
  }
}