import React, { Component } from 'react';
import SubMenu from '../SubMenu/SubMenu';
import { renderText } from '../../../helpers/api'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class DropDownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

        
  render () {
    const items = this.props.items;
    const level = this.props.depthLevel;
    const currentClass = 'current-menu-item';
    const handleClick = item => e => {
      const extern = item.classes.indexOf('nav-extern') !== -1;
      !extern && this.props.handleClick(e, item);
    };
    

    return (
      
      <ul className={!level ? 'main-menu list-unstyled d-flex' : 'sub-menu list-unstyled d-flex'}>
      {
        items.map(item => {
          const current = this.props.isCurrentMenuItem(item.url_components);
          const id = 'btn' + item.menu_item_id;
          return (
            
            <li key={'menu_item_' + item.menu_item_id} className={'nav_item ' + (current ? currentClass : '') + ' ' + (item.classes.join(' '))}>            
                {item.children ? (            
                    <Dropdown key={id} direction="right" isOpen={this.state[id]} toggle={() => { this.setState({ [id]: !this.state[id] }); }}>
                        <DropdownToggle tag="span" className="-dropdown">{renderText(item.title)}</DropdownToggle>
                        <DropdownMenu>
                            {item.children.map(child =>{
                                return (
                                    <DropdownItem tag="span" key={child.menu_item_id}><a href={child.url} onClick={handleClick(child)} target={child.target}>{renderText(child.title)}</a></DropdownItem>
                                )
                            })}
                        </DropdownMenu>
                    </Dropdown>
                ) : (
                    <div>
                        <a href={item.url} className="-link" onClick={handleClick(item)} target={item.target}>{renderText(item.title)}</a>
                        {
                            item.children && <SubMenu items={item.children} depthLevel={level + 1} handleClick={this.props.handleClick} isCurrentMenuItem={this.props.isCurrentMenuItem} currentPath={this.props.currentPath}/>
                        }

                    </div>      
                )}
            </li>
          );
        })
      }
      </ul>
    );
  }
}

export default DropDownMenu
