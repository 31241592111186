import React, { Component } from 'react';
import { findDOMNode } from 'react-dom'
import { inject, observer } from 'mobx-react';
import VisibilitySensor from 'react-visibility-sensor';
import VideoScrollerItem from './VideoScrollerItem';
import Slider from "react-slick";
import { WINDOW_EXISTS } from '../../../helpers/viewport';
//import Img from '../../elements/Img/Img';

/**
 * A embded video teaser Scroller, used on Homepage Page to display posts in  slick slider
 */
@inject('archivesStore')
@observer
class VideoScroller extends Component {

  slider = null;
  sliderElement = null;

  settings = {
    infinite: false,
    speed: 600,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    adaptiveHeight: true,
    arrows: true,
    dots: false,
  };

  handleOnWheel = (e) => {
    e.preventDefault();
    const deltaXAbs = Math.abs(e.deltaX);
    if (e.deltaX < 0 && deltaXAbs > 3) {
      this.slider.slickNext();
    } else if (deltaXAbs > 3){
      this.slider.slickPrev();
    }
  }

  componentDidMount() {
    const elem = findDOMNode(this.slider);
    if (WINDOW_EXISTS && elem ) {
      this.sliderElement = elem;
      //this.sliderElement.addEventListener('wheel', this.handleOnWheel, false);
    }
  }

  componentWillUnmount(){
    if(this.sliderElement) {
      //this.sliderElement.removeEventListener('wheel', this.handleOnWheel, false);
    }
  }

  render() {
    const videos = this.props.videos;
    return (
      <VisibilitySensor partialVisibility={true} delayedCall={true} offset={{top:200, bottom: 200}}>
        {({isVisible}) =>
          <div className="nc-slider__container -video">
            <div className={'nc-slider__wrapper' + (isVisible ? ' nc-slider__visible' : ' nc-slider__hidden')}>
              <Slider className="nc-slider" {...this.settings} ref={slider => (this.slider = slider)}>
              {videos.map((video, i) => {
                return (
                  <VideoScrollerItem key={i} {...video}></VideoScrollerItem>
                );
              })}
              </Slider>
            </div>
          </div>
        }
      </VisibilitySensor>
    );
  }
}

export default VideoScroller;