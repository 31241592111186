import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SubMenu from '../../components/nav/SubMenu/SubMenu';
import FirstAidTab from '../../components/elements/FirstAidTab/FirstAidTab';
import { observer, inject } from 'mobx-react';

@inject('navigationStore', 'routerStore')
@observer
class Header extends Component {
  menuItems = [];

  constructor(props) {
    super(props);
    this.menuItems = props.navigationStore.primaryNavigationItems;
  }

  render() {
    const currentPath = this.props.routerStore.location.pathname;
    const isCurrentMenuItem = this.props.navigationStore.isCurrentMenuItem;
    const handleClick = this.props.navigationStore.handleClick;
    const toggleNav = this.props.navigationStore.toggleNav;

    const show = ! this.props.navigationStore.isModal;

    return show ? (
      <header className="nc-header">
        <div className="row">
          <div className="col-12">
            <nav className="nc-main-nav -mobile">
              <div className={this.props.navigationStore.navIsActive ? 'nc-main-nav__container collapse show' : 'nc-main-nav__container collapse hide'}>
                <div className="nc-main-nav__menu">
                  <div className="row nc-main-nav__header">
                    <div className="col-6">
                      <h3><Link to='/'>Startseite</Link></h3>
                    </div>
                    <div className="col-6">
                      <div className="d-flex justify-content-end" onClick={toggleNav}>
                        <span className={'navbar-toggler-icon' + (this.props.navigationStore.navIsActive ? ' is_active' : '')}></span>
                      </div>
                    </div>
                  </div>
                  <SubMenu items={this.menuItems} depthLevel={0} isCurrentMenuItem={isCurrentMenuItem} handleClick={handleClick} currentPath={currentPath}/>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-lg-3">
            <Link className="nc-header__logo" to='/'>SKA</Link>
          </div>
          <div className="col-6 col-lg-9">
            {/* <nav className="nc-main-nav">
              <div className={'nc-main-nav__container d-none'}>
                <div className="nc-main-nav__menu">
                  <SubMenu items={this.menuItems} depthLevel={0} isCurrentMenuItem={isCurrentMenuItem} handleClick={handleClick} currentPath={currentPath} />
                </div>
              </div>
            </nav> */}
            <div className="d-block" onClick={toggleNav}>
              <div className="d-flex justify-content-end">
                <span className="navbar-toggler-text">MENU</span> <span className={'navbar-toggler-icon' + (this.props.navigationStore.navIsActive ? ' is_active' : '')}></span>
              </div>
            </div>
          </div>
        </div>
        <FirstAidTab/>
      </header>
    ) : (
      null
    );
  }
}
export default Header;
