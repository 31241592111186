import {RouterStore} from 'mobx-react-router';
import NavigationStore from './UI/NavigationStore';
import ScrollStore from './UI/ScrollStore';
import PagesStore from './PagesStore';
import ArchivesStore from './ArchivesStore';
import SidebarsStore from './SidebarsStore';
import CookieStore from './UI/CookieStore';
import BlockDataStore from './UI/BlockDataStore';
import QuizStore from './UI/QuizStore';
import WindowStore from './UI/WindowStore';
import wpapi from 'wpapi/browser/wpapi.min';
// import wpapi from 'wpapi';
import ScrollManager from 'window-scroll-manager';

import { getApiconfig, setHeaders } from '../helpers/api'
import { syncHistoryWithStore } from 'mobx-react-router';
import { createBrowserHistory } from 'history';

const wpApi = new wpapi(getApiconfig());
const scrollManager = new ScrollManager();
setHeaders(wpApi);

const routerStore = new RouterStore();
const windowStore = new WindowStore();
const cookieStore = new CookieStore();
const scrollStore = new ScrollStore(routerStore, windowStore, scrollManager);


const browserHistory = createBrowserHistory();
export const history = syncHistoryWithStore(browserHistory, routerStore);

export default {
  routerStore: routerStore,
  navigationStore: new NavigationStore(routerStore),
  blockDataStore: new BlockDataStore(cookieStore),
  quizStore: new QuizStore(scrollStore),
  windowStore: windowStore,
  scrollStore:scrollStore,
  pagesStore: new PagesStore(wpApi),
  archivesStore: new ArchivesStore(wpApi, routerStore),
  sidebarsStore: new SidebarsStore(wpApi),
  // place for other stores...
};
