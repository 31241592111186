export default class CookieStore {

  settings = {}
  borlabsCookie = false;

  constructor() {
    if ( typeof window !== 'undefined' && typeof window.NC !== 'undefined' && window.NC.cookie ) {
      this.settings = window.NC.cookie;
    }
  }

  getBorlabsCookie = () => {
    if ( typeof window !== 'undefined' && typeof window.BorlabsCookie !== 'undefined' ) {
      this.borlabsCookie = window.BorlabsCookie;
    }
    return this.borlabsCookie;
  }

  // Vimeo
  addConsentVimeo = () => {
    this.settings.vimeo = true;
    const borlabsCookie = this.getBorlabsCookie();
    return borlabsCookie && borlabsCookie.addConsent('media', 'vimeo');
  }

  checkVimeo = () => {
    return this.settings.vimeo;
  }

  removeConsentVimeo = () => {
    this.settings.vimeo = false;
    const borlabsCookie = this.getBorlabsCookie();
    return borlabsCookie  && borlabsCookie.removeConsent('media', 'vimeo');
  }

  // Youtube
  addConsentYoutube = () => {
    this.settings.youtube = true;
    const borlabsCookie = this.getBorlabsCookie();
    return borlabsCookie && borlabsCookie.addConsent('media', 'youtube');
  }

  checkYoutube = () => {
    return this.settings.youtube;
  }

  removeConsentYoutube = () => {
    this.settings.youtube = false;
    const borlabsCookie = this.getBorlabsCookie();
    return borlabsCookie && borlabsCookie.removeConsent('media', 'twitter');
  }

  // Twitter
  addConsentTwitter = () => {
    this.settings.twitter = true;
    const borlabsCookie = this.getBorlabsCookie();
    return borlabsCookie && borlabsCookie.addConsent('media', 'twitter');
  }

  checkTwitter = () => {
    return this.settings.twitter;
  }

  removeConsentTwitter = () => {
    this.settings.twitter = false;
    const borlabsCookie = this.getBorlabsCookie();
    return borlabsCookie  && borlabsCookie.removeConsent('media', 'twitter');
  }
  
}