import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteWithSubRoutes } from './../../routes/routes';

@inject('pagesStore')
@observer
class HomePage extends Component {

  componentDidMount() {
    document.body.classList.add('page');
  }
  componentWillUnmount() {
    document.body.classList.remove('page');
  }

  render() {
    const routes = this.props.routes;
    return (
      <div className="nc-home">
        <div className="nc-home__container">
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={'HomePage_' + route.path} {...route} siblingPaths={routes.map((route => route.path))}/>
        ))}
        </div>
      </div>
    );
  }
}

export default HomePage;
