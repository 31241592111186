import { Component } from 'react';
import { inject } from 'mobx-react';
import { WINDOW_EXISTS } from '../../../helpers/viewport'

@inject('routerStore')
class ScrollToTop extends Component {


  constructor(props) {
    super(props);
    this.history = this.props.routerStore.history;
  }

  componentDidMount() {
    this.shouldScroll = !this.props.onHistoryPush || this.history.action === 'PUSH';
    if(this.shouldScroll && WINDOW_EXISTS) {
      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate() {
    this.shouldScroll = !this.props.onHistoryPush || this.history.action === 'PUSH';
    if(this.shouldScroll && WINDOW_EXISTS) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}
export default ScrollToTop;