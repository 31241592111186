import React, { Component } from 'react';
import { inject } from 'mobx-react';
// import { renderText } from '../../../helpers/api';
import VisibiltySensor from 'react-visibility-sensor';
import Img from '../../elements/Img/Img';
import { Link } from 'react-router-dom';

@inject('routerStore')
class VideoScrollerItem extends Component {

  show = false;
  onVisibiltyChange = (isVisible) => {
    this.show = this.show || isVisible;
  }

  render() {
    const src = this.props.fields.video_post_video.img;
    const to = {
      pathname: this.props.urlc.path,
      state: { backLocation: this.props.routerStore.location.pathname },
    };
    /*<div className="-text-wrapper"><Link to={to}>{renderText(this.props.title)}</Link></div>}*/
    return  (
      <div className="nc-slider__item">
            <div className="-wrapper">
              <div className="aspect-content">
                <VisibiltySensor partialVisibility={true} onChange={this.onVisibiltyChange}>
                  {({isVisible}) => 
                    isVisible || this.show ? (
                      <Link to={to}><Img className="w-100" withLoader alt={this.props.fields.video_post_caption && this.props.fields.video_post_caption} src={src}/></Link>
                    ) : ( 
                      <span>&nbsp;</span>
                    )
                  }
                </VisibiltySensor>
              </div>
            </div>
      </div>
    );
  }
}

export default VideoScrollerItem