import React from 'react';
import { Route, matchPath } from 'react-router-dom';
import Archive from '../containers/Archive/Archive';
import Single from '../containers/Single/Single';
import SingleVideo from '../containers/SingleVideo/SingleVideo';
import SingleQuiz from '../containers/SingleQuiz/SingleQuiz';
import SingleSupport from '../containers/SingleSupport/SingleSupport';
import HomePage from '../containers/HomePage/HomePage';
import StartStage from '../containers/HomePage/StartStage';
import RacismStage from '../containers/HomePage/RacismStage';
import ApproachesStage from '../containers/HomePage/ApproachesStage';
import NeutralityStage from '../containers/HomePage/NeutralityStage';
import NoMatch from '../containers/NoMatch/NoMatch';
import {default as stores } from '../stores/index';

const preparePostTypesRoutes = ( customRoutes ) => {
  const postTypesRoutes = stores.navigationStore.postTypesRoutes;
  const routes = [];
  for (const p_key in postTypesRoutes) {
    if ('_meta' !== p_key && postTypesRoutes.hasOwnProperty(p_key)) {
      const postType = postTypesRoutes[p_key];
      // first define route for posts of this post_type if it has rewrite slug
      // and not defined in customRoutes like video-post
      if ( postType._rewrite ) {
        const shema = '/' + postType._rewrite + '/:id/';
        if ( !customRoutes.find((route) => route.path === shema) ) {
          routes.push(
            {
              path: shema,
              exact: true,
              component: Single,
              apiParams: {
                postType: p_key,
              },
            }
          );
        }
      }
      // define this post_types archive route
      if ( postType._link ) {
        const shema = postType._link.replace( postTypesRoutes._meta.home_url, '');
        if (shema && !customRoutes.find((route) => route.path === shema)) {
          routes.push(
            {
              path: shema,
              exact: true,
              component: Archive,
              apiParams: {
                postType: p_key,
                type: 'post_type_archive'
              },
            }
          );
        }
      }
      // define routes for taxonomy archive sites
      for (const t_key in postType) {
        if ('_' !== t_key.charAt(0) &&  postType.hasOwnProperty(t_key)) {
          const taxonomy = postType[t_key];
          for (const term_slug in taxonomy) {
            if (taxonomy.hasOwnProperty(term_slug) && '_' !== term_slug.charAt(0)) {
              const term = taxonomy[term_slug];
              if (!customRoutes.find((route) => route.path === term.link)) {
                routes.push(
                  {
                    path: term.link,
                    exact: true,
                    component: Archive,
                    apiParams: {
                      restBase: taxonomy._rest_base,
                      postType: p_key,
                      taxonomy: t_key,
                      termId: term.id,
                      type: 'taxonomy'
                    }
                  }
                );
              }
            }
          }
        }
      }
    }
  }
  return routes;
}

const customRoutes = [
  {
    path: "/quiz/",
    exact: true,
    component: SingleQuiz,
  },
  {
    path: "/(rassismus|nichtneutral|handlungsansaetze|)?",
    exact: true,
    component: HomePage,
    routes: [
      {
        path: "/",
        exact: true,
        children: StartStage
      },
      {
        path: "/rassismus/",
        exact: true,
        children: RacismStage
      },
      {
        path: "/nichtneutral/",
        exact: true,
        children: NeutralityStage
      },
      {
        path: "/handlungsansaetze/",
        exact: true,
        children: ApproachesStage
      },
    ]
  },
  {
    path: "/videos/:id/",
    exact: true,
    component: SingleVideo,
    apiParams: {
      postType: "video-post"
    }
  },
  
  {
    path: "/hier-finden-sie-unterstuetzung/",
    exact: true,
    component: SingleSupport,
  },
  {
    path: "/:id([\\w-]*)",
    exact: true,
    component: Single,
  },
  {
    path: "*",
    component: NoMatch,
  }
];
const postTypesRoutes = preparePostTypesRoutes(customRoutes);
const ROUTES = [...postTypesRoutes, ...customRoutes];

// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work
const RouteWithSubRoutes = (route) => {
  if (route.children) {
    return (
      <Route
        path={route.path}
        exact={route.exact}
        children={({ ...props })=> {
          // pass the sub-routes down to keep nesting
          return <route.children {...props} routes={route.routes} path={route.path} siblingPaths={route.siblingPaths}/>
        }}
      />);
  } else {
    return (
      <Route
        location={route.location}
        path={route.path}
        exact={route.exact}
        render={ props => {
            // pass the sub-routes down to keep nesting
            return <route.component {...props} routes={route.routes} path={route.path} siblingPaths={route.siblingPaths} store={route.store} apiParams={route.apiParams}/>
          }}

      />);
    }
};

const getCurrentRoute = () => {
  const store = stores.routerStore;
  const routes = ROUTES;
  let match = null;
  for (let i=0; i< routes.length; i++) {
    match = matchPath(store.location.pathname, routes[i]);
    if (match) {
      return routes[i];
    }
  }
  // nothing found return nomatch route
  return routes[routes.length - 1];
};

/**
 * 
 * @param {integer} id term id
 * @param {string} taxonomy slug
 */
const getRouteByTermId = (id, taxonomy = null) => {
  const routes = ROUTES;
  return id !== undefined && routes.find((route) => route.apiParams && route.apiParams.termId === id);
};

/**
 * 
 * @param {integer} id term id
 * @param {string} taxonomy slug
 *
 * @returns {object|boolean} route object or false
 */
const getRouteByPath = ( path ) => {
  const routes = ROUTES;
  return path !== undefined && routes.find((route) => matchPath(path, route));
};

export { ROUTES, RouteWithSubRoutes, getCurrentRoute, getRouteByTermId, getRouteByPath };
