import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { renderTitle, renderText } from '../../helpers/api';
import  NoMatch  from '../NoMatch/NoMatch';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
import ScrollToHash from '../../components/nav/ScrollToHash/ScrollToHash';
import LoadingAnimation from '../../components/elements/LoadingAnimation/LoadingAnimation';


@inject('pagesStore')
@observer
class Single extends Component {

  slug = '';
  store = {};

  constructor(props) {
    super(props);
    this.getCurrentSlug();
    this.store = this.props.store ? this.props.store : this.props.pagesStore;
  }

  renderHead() {
    if (this.store.isFetched.get(this.slug) && this.store.data.posts[this.slug].title) {
      return renderTitle(this.store.data.posts[this.slug].title);
    } else {
      return null;
    }
  }

  getCurrentSlug = () => {
    if (this.props.match && this.props.match.params) {
      const slug = this.props.match.params.id;
      this.slug = '' !== slug ? slug : 'homepage';
    }
  }
  componentDidMount() {
    this.store.fetchIfNeeded(this.slug);
    document.body.classList.add('page');
  }
  componentWillUnmount() {
    document.body.classList.remove('page');
  }

  componentDidUpdate() {
    this.store.fetchIfNeeded(this.slug);
  }

  render() {
    this.getCurrentSlug();
    const isFetched = this.store.isFetched.get(this.slug);
    const post = this.store.data.posts[this.slug];

    const scrollToTop = !this.props.location.hash && (!this.props.location.state || !this.props.location.state.scrollTo);

    if ((isFetched && false === post) || (this.store.isFetchedAll && !post)) {
      return <NoMatch location={this.props.location} />;
    }

    let terms = '';
    if (isFetched ) {
      post.terms.category && post.terms.category.map((term, i) => {
        terms += 'nc-' + post.terms.category[i].slug + ' ';
        return false;
       })
    }

    return (
      <div className={'nc-page ' + terms}>
        { isFetched ? (
          <div className={'nc-page__content '}>
            {scrollToTop && <ScrollToTop onHistoryPush={true}/>}
            <ScrollToHash/>
            {this.renderHead()}
            <div className="row">
              <div className="col-12 col-lg-8 offset-lg-2">{renderText(post.content)}</div>
            </div>
          </div>
        ) : <LoadingAnimation/>
        }
      </div>
    );
  }
}

export default Single;
