import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Link } from 'react-router-dom';

@inject('routerStore')
export default class CloseButton extends Component {

  handleClick = (e) => {
    e.preventDefault();
    this.props.routerStore.goBack()
    return false;
  }

  render() {
    const classNames = this.props.className ? this.props.className + ' ' : '';
    //@todo make a button instead <a href...>
    //@see https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
    return (
      <div className={classNames + 'nc-close-button'}>
        {true === this.props.backLocation ?
          /*eslint-disable-next-line*/
          <a className="nc-close-button__icon history" href="" onClick={this.handleClick}>schliessen</a>
        :
          <Link className="nc-close-button__icon" to={this.props.backLocation}>schliessen</Link>
        }
      </div>
    );
  }
}